// import { useRouter } from "next/router";
// import { WP_CMS } from "../../constants/env";
// import { PageInfo, Post } from "../../types/graphql";
// import { usePaginationStore } from "../../components/config/store/usePaginationStore";
//
// export type BlogData = {
//   data: {
//     posts: {
//       nodes: Post[];
//       pageInfo: PageInfo;
//     };
//   };
//   extensions: object;
// };
//
// export function useBlogList() {
//   const router = useRouter();
//   const { after } = usePaginationStore((state) => state);
//
//   function postListQuery(first?: number) {
//     return {
//       query: `query fetchAllPosts {
//   posts(first: ${first ? first : 100}, after: ${
//         after ? `"${after}"` : "null"
//       }, where: {categoryName: "blog-${router.locale}"}) {
//     pageInfo {
//     hasNextPage
//       endCursor
//       startCursor
//       hasPreviousPage
//     }
//     nodes {
//       id
//       excerpt
//       slug
//       author {
//         node {
//         slug
//           avatar {
//             url
//           }
//           name
//           id
//         }
//       }
//       featuredImage {
//         node {
//           sourceUrl
//         }
//       }
//       tags {
//         nodes {
//           name
//         }
//       }
//       categories {
//         nodes {
//           name
//         }
//       }
//       modified
//       postId
//       title
//     }
//
//   }
// }`,
//     };
//   }
//
//   async function fetchPostsFromWP(first?: number): Promise<BlogData> {
//     const request = await fetch(WP_CMS, {
//       method: "POST",
//       headers: {
//         "Content-type": "application/json",
//       },
//       body: JSON.stringify(postListQuery(first)),
//     });
//
//     return await request.json();
//   }
//
//   return {
//     fetchPostsFromWP,
//   };
// }


import {ref, onValue, DataSnapshot, get, getDatabase} from "firebase/database";

import { QueryKeys } from "./query-client";
import { useQuery } from "react-query";
import * as firebase from "firebase/app";
import {firebaseConfig} from "../../constants/env";
import {useRouter} from "next/router";

export interface BlogData {
  published: boolean;
  timestamp: string;
  image: string;
  title: string;
  description: string;
  content: string;
  author: string;
  urlSlug: string;
  category: string;
  specialistUrl?: string;
}

export type BlogDataProps = {
  blog: BlogData;
};

export function useBlogList() {

  const router = useRouter();


  if (!firebase.getApps().length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.getApp(); // if already initialized, use that one
  }
  const database = getDatabase(firebase.getApp());

  const publishedBlogsRef = ref(database,     `published/blog/${router.locale}`);
  const {
    data: blogs,
    isFetching: blogsAreLoading,
    isError: blogsError,
  } = useQuery([QueryKeys.BLOGS, router.locale], () => getEntries(), {
    keepPreviousData: true,
    retry: 2,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });

  // wrap blog entries subscription into a promise to be used with react-query
  function getEntries(): Promise<Record<string, BlogData> | null> {
    return new Promise((resolve, reject) => {
      onValue(
          publishedBlogsRef,
          async (snapshot) => {
            const publishedEntries: Record<string, BlogData> = snapshot.val();
            resolve(publishedEntries);
          },
          (error) => {
            reject(error);
          }
      );
    });
  }

  /*
  Suggest additional blog articles
   */
  function getFirstNArticles(
      blogs: Record<string, BlogData>,
      size: number,
      blogArticle?: BlogData
  ) {
    const firstElements: Record<string, BlogData> = {};
    const categoryBlogEntries: Record<string, BlogData> = {};
    /*
    Add only blogs in the same category and exclude current article
     */
    if (blogArticle) {
      Object.keys(blogs).map((key) => {
        const entry = blogs[key];
        if (
            entry.category === blogArticle.category &&
            entry.urlSlug !== blogArticle.urlSlug
        ) {
          categoryBlogEntries[key] = entry;
        }
      });
    }

    if (Object.keys(categoryBlogEntries).length > 0) {
      const entries = Object.keys(categoryBlogEntries).sort((a, b) => comparePosts(a, b, blogs)).slice(0, size);
      entries.map((key) => {
        firstElements[key] = categoryBlogEntries[key];
      });
    } else {
      const entries = Object.keys(blogs).sort((a, b) => comparePosts(a, b, blogs)).slice(0, size);
      entries.map((key) => {
        firstElements[key] = blogs[key];
      });
    }

    return firstElements;
  }

  function comparePosts(a: string, b: string, blogs: Record<string, BlogData>) {

    const aBlog = blogs[a];
    const bBlog = blogs[b]

    if (aBlog && bBlog) {
      if (new Date(aBlog.timestamp) > new Date(bBlog.timestamp)) {
        return -1;
      } else if (new Date(aBlog.timestamp) < new Date(bBlog.timestamp)) {
        return 1;
      }
    }
    // a must be equal to b
    return 0;

  }

  return { blogs, blogsAreLoading, blogsError, getFirstNArticles, comparePosts };
}