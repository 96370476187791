import { useColors } from "../../../../hooks/colors/useColors";
import { Container } from "../../../UI/container/Container";
import { Grid } from "@mantine/core";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandTwitter,
} from "@tabler/icons";
import { currentYear, isPhone, tablet } from "../../../../constants/env";
import Link from "next/link";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import { Address } from "../address/Address";
import { Fragment, useEffect, useState } from "react";
import { AppLinkContainer } from "./app-link-container";

type Props = {
  isVisible: boolean;
  delay?: boolean;
};

export function Footer({ isVisible, delay }: Props) {
  const { colors } = useColors();
  const { t } = useTranslation("common");
  const [delayed, setDelayed] = useState(false);

  useEffect(() => {
    if (delay) setDelayed(true);
    setTimeout(() => {
      setDelayed(false);
    }, 1000);
  }, [delay]);

  return (
    <Fragment>
      {!isPhone && isVisible && !delayed && (
        <Fragment>
          <Address hideIFrame />
          <div
            style={{
              minHeight: "10vh",
              backgroundColor: colors.primary,
              paddingBottom: isPhone ? "16px" : undefined,
            }}
            className="flex flex-col justify-center"
          >
            <Container noHeight>
              <Grid className="flex justify-between">
                <Grid.Col
                  span={{ base: 12, md: 4 }}
                  className="flex justify-center items-center"
                >
                  <div>
                    <Link href="/">
                      <Image
                        alt="Psiworks"
                        className="hover"
                        width={tablet ? 220 : 300}
                        height={53}
                        src="/img/psi-works-log.svg"
                      />
                    </Link>
                  </div>
                </Grid.Col>
                <Grid.Col
                  span={{ base: 12, md: 4 }}
                  className="flex justify-center items-center"
                >
                  <div>
                    <div className="flex justify-center gap-6">
                      <Link
                        href="https://twitter.com/Psiworks_pt"
                        aria-label="Facebook"
                      >
                        <IconBrandFacebook color="white" />
                      </Link>
                      <Link
                        href="https://instagram.com/psiworks.pt"
                        aria-label="Instagram"
                      >
                        <IconBrandInstagram color="white" />
                      </Link>
                      <div className="flex justify-between gap-6">
                        <Link
                          href="https://twitter.com/Psiworks_pt"
                          aria-label="Twitter"
                        >
                          <IconBrandTwitter color="white" />
                        </Link>
                      </div>
                    </div>
                    <AppLinkContainer />
                  </div>
                </Grid.Col>
                <Grid.Col
                  span={{ base: 12, md: 4 }}
                  className="flex justify-center items-center"
                >
                  <Link href="https://www.livroreclamacoes.pt/inicio">
                    <span className="text-white"> {t("complaint")}</span>
                  </Link>
                </Grid.Col>
              </Grid>
              <span className="flex justify-center align-center mt-2 !text-white">
                {`© ${currentYear} Psiworks`}
              </span>
            </Container>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}
